

import {XModal} from "@xnpmpackages/xcomponents/dist/index";
import {defineComponent} from 'vue'
import Xapi from "@xnpmpackages/xcomponents/dist/utils/xapi";

export default defineComponent({
  name: "SelfDiag",
  data() {
    return {
      data: null as any,
    }
  },
  methods: {

    show() {
      this.data = null
      this.$refs.XModal.show()
      this.selfCheck()
    },
    async selfCheck() {

      try {

        Xapi.showBlockModal('Выполнение диагносики...');

        let response = await Xapi.post(
          '/common-actions',
          {
            action: 'selfCheck',
          },
        );

        this.data = response.data.results


      } finally {
        Xapi.hideBlockModal();
      }

    },

  },
  computed: {},
  mounted() {
  },
  components: {
    XModal,
  }
})

