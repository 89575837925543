import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "card",
  style: {"display":"flex","justify-content":"space-between"}
}
const _hoisted_2 = { style: {"display":"flex","align-items":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThemeSwitcher = _resolveComponent("ThemeSwitcher")!
  const _component_Button = _resolveComponent("Button")!
  const _component_FileInput = _resolveComponent("FileInput")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_SelfDiag = _resolveComponent("SelfDiag")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ThemeSwitcher)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_Button, {
          class: "p-button p-button-outlined mr-3",
          icon: "fas fa-stethoscope",
          label: "Провести самодиагностику",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$refs.SelfDiag.show()))
        }),
        _createVNode(_component_Button, {
          class: "p-button p-button-outlined mr-3",
          icon: "fas fa-medkit",
          label: "Выгрузить информацию для технической поддержки",
          onClick: $options.downloadSupport
        }, null, 8, ["onClick"]),
        _createVNode(_component_FileInput, {
          ref: "uploadMedoMessageFileInput",
          inline: "",
          label: "Положить контейнер во входящий тракт",
          onFileChange: $options.uploadMedoMessage
        }, null, 8, ["onFileChange"])
      ])
    ]),
    _createVNode(_component_Settings, { "params-url": "/params" }),
    _createVNode(_component_SelfDiag, { ref: "SelfDiag" }, null, 512)
  ], 64))
}