

import {FileInput, Helper, Settings, ThemeSwitcher} from "@xnpmpackages/xcomponents/dist/index";
import SelfDiag from "@/components/SelfDiag.vue";


export default {
  name: "Params",
  methods: {

    async uploadMedoMessage($file) {

      if (!$file)
        return;

      this.$refs.uploadMedoMessageFileInput.removeFile();

      try {

        this.$xapi.showBlockModal('Загрузка сообщения...');

        let formData = new FormData();
        formData.append('action', 'uploadMedoMessageInbound');
        formData.append('messageZip', $file);

        await this.$xapi.postForm(
          '/common-actions',
          formData,
        );

        this.$xapi.xnotify('Сообщение добавлено в очередь на обработку');

      } finally {
        this.$xapi.hideBlockModal();
      }

    },
    async downloadSupport() {

      this.$xapi.showBlockModal('Выгрузка файла...')
      try {
        await Helper.methods.downloadFileFromUrl('/common-actions/get-support-zip');
      } finally {
        this.$xapi.hideBlockModal();
      }

    }
  },
  components: {
    SelfDiag,
    Settings,
    FileInput,
    ThemeSwitcher
  }
}
